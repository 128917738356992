import React from "react"

// import { Container } from './styles';

import DefaultLayout from "../../layouts/default"

import Content from "../../components/Content"

export default function sucesso() {
  return (
    <DefaultLayout>
      <Content>
        <h1>Pronto!</h1>

        <p>Seu formulário foi enviado com sucesso.</p>
      </Content>
    </DefaultLayout>
  )
}
